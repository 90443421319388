<div class="wrapper">
    <app-header></app-header>
    <div class="page-content">
        <div id="title">
            <h2>DICHIARAZIONE SOSTITUTIVA DI CERTIFICAZIONE PER LA STRUTTURA {{codStruttura}}</h2>
            <p>(ai sensi degli articoli 46 e 47 del D.P.R. 28/12/2000, n° 445)</p>
        </div>

        <div class="alert alert-secondary alert-box text_2 ">
            <form name='form' id="autocertificazioneForm" role="form" [formGroup]="autocertificazioneForm">
                <div class="row align-items-center">
                    <p>Il/La sottoscritto/a <b>{{utente.name}} {{utente.lastName}}</b></p>
                    <p>C.F. <b>{{utente.cf}}</b> nato/a</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione" placeholder="*Comune"
                        [className]="(!this.autocertificazioneForm.get('comuneNascitaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                        formControlName='comuneNascitaDichiarante'>
                    <p>(</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazioneProvincia"
                        [className]="(!this.autocertificazioneForm.get('provinciaNascitaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazioneProvincia'"
                        placeholder="*FI" formControlName='provinciaNascitaDichiarante' maxlength="2">
                    <p>)</p>
                    <p>il</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione"
                        [className]="(!this.autocertificazioneForm.get('dataNascitaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                        placeholder="*gg/mm/yyyy" formControlName='dataNascitaDichiarante'>
                    <div class="row align-items-center">
                        <p>residente in</p>
                        <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione"
                            placeholder="*Comune"
                            [className]="(!this.autocertificazioneForm.get('comuneResidenzaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                            formControlName='comuneResidenzaDichiarante'>
                        <p>(</p>
                        <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazioneProvincia"
                            [className]="(!this.autocertificazioneForm.get('provinciaResidenzaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazioneProvincia'"
                            placeholder="*FI" formControlName='provinciaResidenzaDichiarante' maxlength="2">
                        <p>)</p>
                        <p>Via/Piazza</p>
                        <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione" placeholder="*"
                            [className]="(!this.autocertificazioneForm.get('indirizzoResidenzaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                            formControlName='indirizzoResidenzaDichiarante'>
                        <p>N°</p>
                        <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione" placeholder="*"
                            [className]="(!this.autocertificazioneForm.get('civicoResidenzaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                            formControlName='civicoResidenzaDichiarante'>
                    </div>
                </div>
                <div class="row align-items-center">
                    <p>Email</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazioneEmail"
                        [className]="(!this.autocertificazioneForm.get('emailDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazioneEmail'"
                        placeholder="*email@email.it" formControlName='emailDichiarante'>
                    <p>Conferma Email</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazioneEmail"
                        [className]="(!this.autocertificazioneForm.get('emailDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazioneEmail'"
                        placeholder="*email@email.it" formControlName='confermaEmailDichiarante'
                        (paste)="preventPaste()">
                </div>
                <div class="row align-items-center">
                    <p></p>
                </div>
                <div class="row align-items-center">
                    <p>In qualità di <b>{{ruolo}}</b></p>
                </div>
                <div class="row align-items-center">
                    <p>Operante nella struttura: <b>{{struttura.tipologia}}</b></p> 
                    <p>denominata: <b>{{struttura.denominazione}}</b></p>
                </div>
                <div class="row align-items-center">
                    <p>con sede in <b>{{struttura.comune}}</b> (<b>{{struttura.provincia}}</b>) Via/Piazza
                        <b>{{struttura.indirizzo}}</b>
                        n° <b>{{struttura.civico}}</b>
                    </p>
                </div>
                <div class="row align-items-center">
                    <p>consapevole delle sanzioni penali, nel caso di dichiarazioni non veritiere, di formazione o uso
                        di atti falsi, richiamante dall'articolo 76 del DPR 445/2000,</p>
                </div>
            </form>
            <form name='form' role="form" [formGroup]="consensoForm">
                <div class="row justify-content-center">
                    <p><b>*DICHIARA</b></p>
                </div>
                <div class="row justify-content-center">
                    <div *ngIf="this.consensoForm.get('procedimentiPenali').valid && noValidForm" class="form-errors">
                        Obbligatorio</div>
                </div>
                <div class="row justify-content-start checkBox-section">
                    <div>
                        <input type="checkbox" class="form-check-input" id="procedimentiPenali"
                            formControlName='procedimentiPenali'>
                    </div>
                    <p>di non essere sottoposto a procedimenti penali, né di essere destinatario di provvedimenti che
                        riguardano l’applicazione di misure di sicurezza e di misure di prevenzione;</p>
                </div>
                <div class="row justify-content-center">
                    <div *ngIf="this.consensoForm.get('sentenzaCondanna').valid && noValidForm" class="form-errors">
                        Obbligatorio</div>
                </div>
                <div class="row justify-content-start checkBox-section">
                    <div>
                        <input type="checkbox" class="form-check-input" id="sentenzaCondanna"
                            formControlName='sentenzaCondanna'>
                    </div>
                    <p>di non avere a proprio carico nessuna sentenza di condanna passata in giudicato per reati gravi
                        che incidono sulla moralità professionale, in particolare relativi ai delitti contro la persona,
                        di pedofilia, di sfruttamento della prostituzione o riguardanti le sostanze stupefacenti</p>
                </div>
                <div class="row justify-content-center">
                    <div *ngIf="this.consensoForm.get('sentenzaPassata').valid && noValidForm" class="form-errors">
                        Obbligatorio</div>
                </div>
                <div class="row justify-content-start checkBox-section">
                    <div>
                        <input type="checkbox" class="form-check-input" id="sentenzaPassata"
                            formControlName='sentenzaPassata'>
                    </div>
                    <p>di non essere sottoposto a procedimenti penali e di non aver riportato a proprio carico nessuna
                        sentenza passata in giudicato per i reati previsti dalla legge 19 luglio 2019, n. 69, “Tutela
                        delle vittime di violenza domestica e di genere”.</p>
                </div>
                <div class="row align-items-center">
                    <p>Ai sensi dell’art. 10 della legge 675/1996 e successive modificazioni, le informazioni indicate
                        nella presente dichiarazione verranno utilizzate unicamente per le finalità per le quali sono
                        state acquisite.
                    </p>
                </div>
            </form>
        </div>
        <div class="row justify-content-end">
            <div class="align-right">
                <button type="button" class="btn btn-primary" style="margin: 5px;" (click)="logout()">Annulla</button>
                <button type="button" class="btn btn-primary" style="margin: 5px;"
                    (click)="validateForm()">Conferma</button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>

<!-- Modale di conferma per l'autodichiarazione -->
<div *ngIf="modal_autocertificazione.open" class="modale-background">
    <div class="modale-content">
        <div class="modal-dialog">
            <div class="modal-content modal-content-istruzioni">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Conferma operazione per la struttura {{codStruttura}}</h5>
                    <button type="button" class="close" (click)="modal_autocertificazione.closeModal()"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div [ngClass]="'overflow-auto h-255'" id="scrollable">
                    <div class="modal-body modal-content-istruzioni-content" id="istruzioni-content">
                        <p>Il/La sottoscritto/a <b>{{this.autocertificazioneForm.value.nomeDichiarante}}
                                {{this.autocertificazioneForm.value.cognomeDichiarante}}</b> </p>
                        <p>Codice Fiscale: <b>{{this.autocertificazioneForm.value.cfDichiarante}}</b> </p>
                        <p>nato/a a <b>{{this.autocertificazioneForm.value.comuneNascitaDichiarante}}</b> in provincia di
                            <b>{{this.autocertificazioneForm.value.provinciaNascitaDichiarante}}</b>
                        </p>
                        <p> il <b>{{this.autocertificazioneForm.value.dataNascitaDichiarante}}</b> </p>
                        <p>residente a <b>{{this.autocertificazioneForm.value.comuneResidenzaDichiarante}}</b> in provincia
                            di
                            <b>{{this.autocertificazioneForm.value.provinciaResidenzaDichiarante}}</b>
                        </p>
                        <p>Email: <b>{{this.autocertificazioneForm.value.emailDichiarante}}</b></p>
    
    
                        <p>In qualità di <b>{{ruolo}}</b></p>
                        <p>Operante nella struttura: <b>{{struttura.tipologia}}</b></p> 
                        <p>denominata: <b>{{struttura.denominazione}}</b></p>
                        <p>con sede in <b>{{struttura.comune}}</b> (<b>{{struttura.provincia}}</b>) Via/Piazza
                            <b>{{struttura.indirizzo}}</b>
                            n° <b>{{struttura.civico}}</b>
                        </p>
    
    
                        <p style="text-align: center;"><b>DICHIARO</b></p>
                        <p>di non essere sottoposto a procedimenti penali, né di essere destinatario di provvedimenti che
                            riguardano l’applicazione di misure di sicurezza e di misure di prevenzione;</p>
                        <p>di non avere a proprio carico nessuna sentenza di condanna passata in giudicato per reati gravi
                            che incidono sulla moralità professionale, in particolare relativi ai delitti contro la persona,
                            di pedofilia, di sfruttamento della prostituzione o riguardanti le sostanze stupefacenti </p>
                        <p>di non essere sottoposto a procedimenti penali e di non aver riportato a proprio carico nessuna
                            sentenza passata in giudicato per i reati previsti dalla legge 19 luglio 2019, n. 69, “Tutela
                            delle vittime di violenza domestica e di genere”. </p>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="modal_autocertificazione.closeModal()"
                        data-dismiss="modal">Annulla</button>
                    <button type="button" class="btn btn-primary" (click)="saveAutocertificazione()"
                        data-dismiss="modal">Conferma</button>
                </div>
            </div>
        </div>
    </div>
</div>