export class AutocertificazioneRequest {
    nome: string;
    cognome: string;
    codiceStruttura: string;
    cf: string;
    dataNascita: string;
    comuneNascita: string;
    provinciaNascita: string;
    comuneResidenza: string
    provinciaResidenza: string;
    indirizzoResidenza: string;
    civicoResidenza: string;
    email: string;
    tipologiaOperatore: string;
    flagProcPenali: boolean;
    flagSentenzaCondanna: boolean;
    flagSentenzaPassata: boolean;
    utenteCreazione: string;
    dataCreazione: Date;
    utenteModifica: string;
    dataModifica: Date;
    token: string;

    constructor() {
        this.dataCreazione = new Date();
        this.dataModifica = new Date();
    }
}