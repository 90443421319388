<div class="wrapper">
    <app-header></app-header>
    <div class="justify-content-center page-content">
        <div class="home-content">
            <h3>Asso Autocertificazioni - Download Autocertificazione</h3>
            <div class="sezione-seleziona-struttura">
                <h4 class="sezione-nome-utente">
                    {{utente.lastName}} {{utente.name}} <br>
                    Codice Struttura {{codStruttura}}
                </h4>
                <div class="text-center">
                    <a (click)="downloadAutocertificazione()" class="btn btn-primary" style="margin: 5px;">Download
                        Autocertificazione</a>
                    <a (click)="nuovaAutocertificazione()" class="btn btn-primary" style="margin: 5px;">Nuova
                        Autocertificazione</a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>