export class Modal {
  open: boolean;
  title: string;
  message: string;
  personale: any;

  testoBottoneOK: string;
  testoBottoneAnnulla: string;
  funzioneBottoneOK;
  funzioneBottoneAnnulla;

  constructor() {
    this.open = false;
    this.title = "default title";
    this.message = "default message"
  }

  openModal(title: string, message: string) {
    this.open = true;
    this.title = title.toUpperCase();
    this.message = message;
  }

  closeModal() {
    this.open = false;
  }


}
