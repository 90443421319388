import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AamService {

  constructor(private http: HttpClient) { }

  getListaUtenti(listaUtentiRequest: any): Observable<any> {
    return this.http.post<any>(`${environment.aam_be}/utenti/lista`, listaUtentiRequest);
  }
}
