import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../service/auth/auth.service';
import { environment } from 'src/environments/environment';
import { AutocertificazioneService } from './../../service/autocertificazione/autocertificazione.service';

@Component({
  selector: 'app-ultima-autocertificazione',
  templateUrl: './ultima-autocertificazione.component.html',
  styleUrls: ['./ultima-autocertificazione.component.css']
})
export class UltimaAutocertificazioneComponent implements OnInit {

  codStruttura: any;
  tipologiaOperatore: any;
  utente: any;
  url: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private autocertificazioneService: AutocertificazioneService,
  ) {
    this.utente = this.auth.getUtente();
  }

  ngOnInit(): void {
    this.codStruttura = this.route.snapshot.paramMap.get('codStruttura');
    this.tipologiaOperatore = this.autocertificazioneService.tipologiaOperatoreDownload;
    // this.url = `${environment.asso_be}/asso/autocertificazioni-download/?cf=${this.utente.cf}&codStruttura=${this.codStruttura}&tipologiaOperatore=${this.tipologiaOperatore}`
  }

  nuovaAutocertificazione() {
    this.router.navigate(['/autocertificazione', this.codStruttura]);
  }

  downloadAutocertificazione(): void {
    this.spinner.show();
    console.log(this.codStruttura)
    this.autocertificazioneService.downloadAutocertificazione(this.utente.cf, this.codStruttura, this.tipologiaOperatore).subscribe(
      response => {
        this.download(response);
        this.spinner.hide();
      },
      error => {
        console.log(error)
        this.spinner.hide();
      }
    );
  }

  download(response) {
    var FileSaver = require('file-saver');
    var file = new Blob([this.base64ToArrayBuffer(response.data)], { type: 'application/pdf' });
    const nameFile = response.message;
    FileSaver.saveAs(file, nameFile)
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
}
