import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { UtilityService } from './../../utility/utility.service'
import { Router } from '@angular/router';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public http: HttpClient, private router: Router, private utility: UtilityService, handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  addToken(request: HttpRequest<any>, token: String): HttpRequest<any> {
    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token
        }
      });
    }
    return request;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(this.addToken(req, sessionStorage.getItem('access_token'))).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401 && response.error && response.error.title === "TokenExpired") {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
            const body = `grant_type=refresh_token&client_id=${environment.client_id}&refresh_token=${sessionStorage.getItem('refresh_token')}`;
            return this.http.post(environment.tokenUrl, body, { headers: headers }).pipe(
              switchMap(data => {
                let jsonRes = JSON.parse(JSON.stringify(data));
                this.utility.storeToken(jsonRes);
                this.refreshTokenSubject.next(jsonRes.access_token);
                this.isRefreshing = false;
                return next.handle(this.addToken(req, jsonRes.access_token));
              }),
              catchError((response: HttpErrorResponse) => {
                this.isRefreshing = false;
                if (response.status === 400 && response.error && (response.error.error_description === "Stale token" || response.error.error_description === "Refresh token expired")) {
                  this.router.navigate(['/sessionExpired']);
                }
                return throwError(response.statusText);
              }));
          } else {
            return this.refreshTokenSubject.pipe(
              filter(token => token != null),
              take(1),
              switchMap(token => {
                return next.handle(this.addToken(req, token));
              }));
          }
        }
        return throwError(response.statusText);
      }));
  }


}