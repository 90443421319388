import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  setUtente(utente): void {
    sessionStorage.setItem('utente', JSON.stringify(utente));
  }


  deleteSession(): void {
    sessionStorage.clear();
    window.location.href = environment.logoutUrl + environment.redirect_uri;
  }

  getUtente(): any {
    return JSON.parse(sessionStorage.getItem('utente'));
  }

  getUtenteMock(): any {
    return {
      "name": "Gaio Giulio",
      "lastName": "Cesare",
      "cf": "SSKCST37S19C678A",
      "codiceStruttura": "10149_10468_10316"
    }
  }
}

