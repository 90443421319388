import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../service/auth/auth.service';
import { ValidationService } from './../../service/form/validation.service';
import { ModalService } from './../../service/modale/modal.service';
import { StrutturaService } from './../../service/struttura/struttura.service';
import { AutocertificazioneService } from './../../service/autocertificazione/autocertificazione.service';
import { AamService } from './../../service/aam/aam.service';
import { Modal } from './../../model/modal';
import { Struttura } from './../../model/struttura';
import { AutocertificazioneRequest } from './../../model/request/autocertificazioneRequest';
import { ListaUtentiRequest } from '../../model/request/listaUtentiRequest';
import * as moment from 'moment';

@Component({
  selector: 'app-autocertificazione',
  templateUrl: './autocertificazione.component.html',
  styleUrls: ['./autocertificazione.component.css']
})
export class AutocertificazioneComponent implements OnInit {

  modal_autocertificazione: Modal;
  autocertificazioneForm: any;
  anagraficaDichiarante: any;
  codiceFiscaleDichiarante: any;
  consensoForm: any;
  utente: any;
  codStruttura: any;
  noValidForm: Boolean;
  struttura: Struttura;
  autocertificazioneRequest: AutocertificazioneRequest;
  listaUtentiRequest: ListaUtentiRequest;
  problem: any;
  aamResponse: any;
  ruolo: any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private strutturaService: StrutturaService,
    private autocertificazioneService: AutocertificazioneService,
    private aamService: AamService
  ) {
    this.modal_autocertificazione = new Modal();
    this.struttura = new Struttura();
    this.listaUtentiRequest = new ListaUtentiRequest();
    this.autocertificazioneRequest = new AutocertificazioneRequest();
  }

  ngOnInit(): void {
    this.noValidForm = false;
    this.utente = this.auth.getUtente();
    this.codStruttura = this.route.snapshot.paramMap.get('codStruttura');
    this.inizializzaAutocertificazioneForm();
    this.inizializzaCheckBoxConsensi();
  }

  preventPaste() {
    this.modalService.openModalGlobal("ATTENZIONE", "Impossibile copiare il contenuto nel campo conferma email", "OK")
    return false;
  };

  validateForm() {
    this.anagraficaDichiarante = this.componiAnagraficaDichiarante();
    if (this.autocertificazioneForm.status != "VALID" ||
      !this.consensoForm.value.procedimentiPenali ||
      !this.consensoForm.value.sentenzaCondanna ||
      !this.consensoForm.value.sentenzaPassata) {
      if (this.controlloCampiObbligatori(this.autocertificazioneForm.controls)) {
        this.modalService.openModalGlobal("ATTENZIONE", "Compilare tutti i campi obbligatori", "OK");
      } else {
        this.controlloFormale(this.autocertificazioneForm.controls);
      }
      this.noValidForm = true;
      window.scrollTo(0, 0);
    } else if (this.controlloConfermaEmail()) {
      this.modalService.openModalGlobal("ATTENZIONE", "Le email inserite non corrispondono", "OK")
    } else {
      this.modal_autocertificazione.openModal("", "");
    }
  }

  logout() {
    this.auth.deleteSession();
  }

  public saveAutocertificazione() {
    this.spinner.show();
    this.componiAutocertificazioneRequest();
    this.autocertificazioneService.saveAutocertificazione(this.autocertificazioneRequest).subscribe(
      () => {
        this.autocertificazioneService.sendMail(this.autocertificazioneRequest).subscribe();
        this.modalService.modal_global.closeModal();
        this.modalService.openModalGlobal("SUCCESSO", "Autocertificazione salvata con successo. Inoltre, è stata spedita una email con la stessa all'indirizzo specificato.", "OK");
        this.spinner.hide();
        this.autocertificazioneService.tipologiaOperatoreDownload = this.ruolo.toUpperCase();
        this.router.navigate(["/ultimaAutocertificazione", this.codStruttura]);
      },
      error => {
        this.modalService.modal_global.closeModal();
        this.spinner.hide();
        this.modalService.openModalGlobal("ERRORE", error.messaggio, "OK");
      }
    );
  }

  private inizializzaAutocertificazioneForm() {
    this.getDatiStruttura();
    this.getDatiUtenteAam();
    this.autocertificazioneForm = this.formBuilder.group({
      nomeDichiarante: [this.utente.name],
      cognomeDichiarante: [this.utente.lastName],
      cfDichiarante: [this.utente.cf],
      comuneNascitaDichiarante: ['', [Validators.required]],
      provinciaNascitaDichiarante: ['', [Validators.required, ValidationService.province]],
      dataNascitaDichiarante: ['', [Validators.required, ValidationService.validateData]],
      comuneResidenzaDichiarante: ['', [Validators.required]],
      provinciaResidenzaDichiarante: ['', [Validators.required, ValidationService.province]],
      emailDichiarante: ['', [Validators.required, ValidationService.validateEmail]],
      confermaEmailDichiarante: ['', [Validators.required, ValidationService.validateEmail]],
      indirizzoResidenzaDichiarante: ['', [Validators.required]],
      civicoResidenzaDichiarante: ['', [Validators.required]],
    });
  }

  private inizializzaCheckBoxConsensi(): void {
    this.consensoForm = this.formBuilder.group({
      procedimentiPenali: [false, [ValidationService.mustbeTrue]],
      sentenzaCondanna: [false, [ValidationService.mustbeTrue]],
      sentenzaPassata: [false, [ValidationService.mustbeTrue]],
    });
  }

  private componiAnagraficaDichiarante() {
    let anagraficaDichiarante = this.autocertificazioneForm.value;
    anagraficaDichiarante.codiceFiscaleDichiarante = this.utente.cf;
    anagraficaDichiarante.nomeDichiarante = this.utente.name;
    anagraficaDichiarante.cognomeDichiarante = this.utente.lastName;
    //anagraficaDichiarante.token = sessionStorage.getItem("access_token").split('.')[1];
    return anagraficaDichiarante;
  }

  private componiAutocertificazioneRequest() {
    this.autocertificazioneRequest.nome = this.autocertificazioneForm.value.nomeDichiarante.toUpperCase();
    this.autocertificazioneRequest.cognome = this.autocertificazioneForm.value.cognomeDichiarante.toUpperCase();
    this.autocertificazioneRequest.codiceStruttura = this.codStruttura.toUpperCase();
    this.autocertificazioneRequest.cf = this.utente.cf.toUpperCase();
    this.autocertificazioneRequest.dataNascita = moment(this.autocertificazioneForm.value.dataNascitaDichiarante, "DD/MM/YYYY").format("YYYY-MM-DD");
    this.autocertificazioneRequest.comuneNascita = this.autocertificazioneForm.value.comuneNascitaDichiarante.toUpperCase();
    this.autocertificazioneRequest.provinciaNascita = this.autocertificazioneForm.value.provinciaNascitaDichiarante.toUpperCase();
    this.autocertificazioneRequest.comuneResidenza = this.autocertificazioneForm.value.comuneResidenzaDichiarante.toUpperCase();
    this.autocertificazioneRequest.provinciaResidenza = this.autocertificazioneForm.value.provinciaResidenzaDichiarante.toUpperCase();
    this.autocertificazioneRequest.indirizzoResidenza = this.autocertificazioneForm.value.indirizzoResidenzaDichiarante.toUpperCase();
    this.autocertificazioneRequest.civicoResidenza = this.autocertificazioneForm.value.civicoResidenzaDichiarante.toUpperCase();
    this.autocertificazioneRequest.email = this.autocertificazioneForm.value.emailDichiarante.toUpperCase();
    this.autocertificazioneRequest.tipologiaOperatore = this.ruolo.toUpperCase();
    this.autocertificazioneRequest.flagProcPenali = this.consensoForm.value.procedimentiPenali;
    this.autocertificazioneRequest.flagSentenzaCondanna = this.consensoForm.value.sentenzaCondanna;
    this.autocertificazioneRequest.flagSentenzaPassata = this.consensoForm.value.sentenzaPassata;
    this.autocertificazioneRequest.utenteCreazione = this.utente.cf.toUpperCase();
    this.autocertificazioneRequest.utenteModifica = this.utente.cf.toUpperCase();
    this.autocertificazioneRequest.token = sessionStorage.getItem("access_token");
  }

  private controlloCampiObbligatori(form) {
    let campoVuoto = false;
    for (const [key, value] of Object.entries(form)) {
      let campo: any;
      campo = value;
      if (campo.errors && campo.errors.required == true) {
        campoVuoto = true;
      }
    }
    if (!this.consensoForm.value.dichiaro || !this.consensoForm.value.chiedo) {
      campoVuoto = true;
    }
    return campoVuoto;
  }

  private controlloFormale(form) {
    let campoValido = true;
    let message = "I seguenti campi non sono validi: ";
    for (const [key, value] of Object.entries(form)) {
      let campo: any;
      campo = value;
      if (campo.errors != null) {
        campoValido = false;
        switch (key) {
          case 'provinciaDichiarante':
            message += "provincia di nascita (2 caratteri), "
            break;
          case 'dataNascitaDichiarante':
            message += "data di nascita (gg/mm/aaaa), "
            break;
          case 'emailDichiarante':
            message += "email, "
            break;
        }
      }
    }
    return campoValido ? "OK" : this.modalService.openModalGlobal("ATTENZIONE CAMPI NON VALIDI", message, "OK");
  }

  private controlloConfermaEmail() {
    let campiEmailDiversi = false;
    if (this.autocertificazioneForm.value.emailDichiarante != this.autocertificazioneForm.value.confermaEmailDichiarante) {
      campiEmailDiversi = true;
    }
    return campiEmailDiversi;
  }

  private getDatiStruttura() {
    this.spinner.show();
    this.strutturaService.findStrutturaByCodiceTripletta(this.codStruttura).subscribe(
      data => {
        this.struttura = data;
        this.spinner.hide();
      },
      error => {
        this.modalService.modal_global.closeModal();
        this.spinner.hide();
        this.modalService.openModalGlobal("ERRORE", error.messaggio, "OK");
      },
    )
  }

  private getDatiUtenteAam() {
    let ruolo = '';
    this.listaUtentiRequest.asset = 'asso-asmi';
    this.listaUtentiRequest.valoreScoper = this.codStruttura;
    this.listaUtentiRequest.codiceFiscale = this.utente.cf;
    this.listaUtentiRequest.validita = "true";
    this.aamService.getListaUtenti(this.listaUtentiRequest).subscribe(
      data => {
        this.aamResponse = data['response'];
        this.ruolo = this.aamResponse[0].attributoValoreAsset.valore.toUpperCase();
      },
      error => {
        this.modalService.modal_global.closeModal();
        this.spinner.hide();
        this.modalService.openModalGlobal("ERRORE", error.messaggio, "OK");
      }
    )
  }
}