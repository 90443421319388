import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './page/home/home.component';
import { InterceptorService } from './service/token/interceptor.service';
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { SessioneScadutaComponent } from './page/sessione-scaduta/sessione-scaduta.component';
import { AutocertificazioneComponent } from './page/autocertificazione/autocertificazione.component';
import { UltimaAutocertificazioneComponent } from './page/ultima-autocertificazione/ultima-autocertificazione.component';
import { SelezionaStrutturaComponent } from './page/seleziona-struttura/seleziona-struttura.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    UtenteNonAbilitatoComponent,
    SessioneScadutaComponent,
    AutocertificazioneComponent,
    UltimaAutocertificazioneComponent,
    SelezionaStrutturaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
