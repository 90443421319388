<footer id="custom-footer">
    <div class="container-fluid container-footer ">
        <div class=" row justify-content-center">
            <div class="col-12 col-md-3 container-logo">
                <img alt="Logo procura della repullica dei minori" class="light-logo"
                    src="assets/img/procura_repubblica_minorenni.png">
            </div>
            <div class="row container-logo col-12 col-md-9">
                <div class="col-12 col-md-3">
                    <img alt="Logo regione Toscana" class="light-logo" src="assets/img/logoToscana.png"
                        style="height:61px;">
                </div>
                <div class="col-12 col-md-3 container-text">
                    <div>
                        <p>Direzione Diritti di cittadinanza e coesione sociale </p>
                        <p>Via Taddeo Alderotti, 26/n</p>
                        <p>50139 FIRENZE</p>
                    </div>
                </div>
                <div class="col-12 col-md-3 container-text">
                    <div>
                        <p>Help desk Lun-Ven: 09:00 - 19:00,</p>
                        <p> Sab: 09:00 - 13:00</p>
                        <p>Numero verde: <a href="tel:800558080" class="cell-footer">800 558080</a></p>
                        <p>mail: <a href="mailto:helpsis@regione.toscana.it"
                                class="email-footer">helpsis@regione.toscana.it</a>
                        </p>
                    </div>
                </div>
                <div class="row container-copyright ">
                    <div class="span">
                        <div style="padding-left: 20px">Regione Toscana © 2021 | <a
                                href="http://fascicolosanitario.regione.toscana.it" class="link-footer">Fascicolo
                                Sanitario
                                Elettronico</a>
                            |
                            <a href="http://www.regione.toscana.it/cittadini/diritti-e-cittadinanza/privacy"
                                class="link-footer">Privacy</a> | <a href="http://www.regione.toscana.it/accessibilita"
                                class="link-footer">Accessibilità</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>