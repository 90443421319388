import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { SessioneScadutaComponent } from './page/sessione-scaduta/sessione-scaduta.component';
import { AutocertificazioneComponent } from './page/autocertificazione/autocertificazione.component';
import { UltimaAutocertificazioneComponent } from './page/ultima-autocertificazione/ultima-autocertificazione.component';
import { SelezionaStrutturaComponent } from './page/seleziona-struttura/seleziona-struttura.component';

@NgModule({
  imports: [RouterModule.forRoot([
    { path: 'home', component: HomeComponent },
    { path: 'sessionExpired', component: SessioneScadutaComponent },
    { path: 'errorPage', component: UtenteNonAbilitatoComponent },
    { path: 'autocertificazione/:codStruttura', component: AutocertificazioneComponent },
    { path: 'ultimaAutocertificazione/:codStruttura', component: UltimaAutocertificazioneComponent },
    { path: 'selezionaStruttura', component: SelezionaStrutturaComponent },
    { path: '**', redirectTo: '/home', pathMatch: 'full' }
  ]
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
