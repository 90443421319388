import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { filter, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UtilityService } from './../../utility/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../modale/modal.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  timerSession;
  sessionExpired: boolean;
  refreshTokenFlag: boolean;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private httpClient: HttpClient,
    private utility: UtilityService
  ) { }

  public getToken(code) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = `grant_type=authorization_code&code=${code}&responseType=code&client_id=${environment.client_id}&scope=profile attributeauthoritymanager-rs/audience assoautocertificazioni-rs/audience&redirect_uri=${environment.redirect_uri}`;
    return this.httpClient.post(environment.tokenUrl, body, { headers: headers }).pipe(catchError(this.handleError));
  }

  public getRefreshToken() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = `grant_type=refresh_token&client_id=${environment.client_id}&refresh_token=${sessionStorage.getItem('refresh_token')}`;
    return this.httpClient.post(environment.tokenUrl, body, { headers: headers }).pipe(catchError(this.handleError))
  }

  public getAuthCode() {
    let date = new Date().getTime();
    let url = `${environment.authUrl}?response_type=code&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}&scope=profile attributeauthoritymanager-rs/audience assoautocertificazioni-rs/audience`
    window.location.href = url;
  }

  public initAuthProcess() {
    let code = (this.getParameterByName('code', window.location.href));
    if (code != null) {
      if (sessionStorage.getItem('access_token') === null) {
        this.getToken(code).subscribe(data => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          this.utility.storeToken(jsonRes)
          this.utility.setUtente(this.decodeToken(jsonRes.access_token))
          this.router.navigate(["/selezionaStruttura"])
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
        });
      }
    }
  }



  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return jsonPayload;
  }

  public isTokenExpired(token) {
    var stringa = JSON.parse(this.decodeToken(token));
    var expiryDate = new Date(stringa.exp * 1000);
    var currentDate = new Date();
    return currentDate > expiryDate;
  }


  public setTimerToken() {
    clearInterval(this.timerSession);
    moment.locale("it");
    let self = this;
    let dateNow = new Date();
    let dateEnd = new Date();
    let minutiSessione = environment.tokenSessionTime - environment.refresh_tokenTime;
    let minutiToken = environment.access_tokenTime;
    let blocchi = Math.floor(minutiSessione / minutiToken);
    dateEnd.setMinutes(dateEnd.getMinutes() + minutiSessione);

    let i = 0;
    var timerSession = setInterval(
      function () {
        i++;
        dateNow.setMinutes(dateNow.getMinutes() + minutiToken);

        self.getRefreshToken().subscribe(data => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          self.utility.storeToken(jsonRes);
        });

        if (i == blocchi) {
          clearInterval(timerSession);
        }
      }, this.convertToMillisecond(minutiToken));
    this.timerSession = timerSession;

  }

  public handleToken() {
    if (sessionStorage.getItem("access_token")) {
      let access_token = sessionStorage.getItem("access_token");
      let refresh_token = sessionStorage.getItem("refresh_token");

      if (this.isTokenExpired(refresh_token) && !this.sessionExpired) {
        this.sessionExpired = true;
        this.router.navigate(['/sessionExpired'])
      } else if (this.isTokenExpired(access_token) && !this.isTokenExpired(refresh_token) && !this.refreshTokenFlag) {
        this.getRefreshToken().subscribe(data => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          this.utility.storeToken(jsonRes);
          this.refreshTokenFlag = false;
        });
        this.refreshTokenFlag = true;
      } else {
        this.setTimerToken();
      }
    }
  }

  convertToMillisecond(minutes) {
    var milliseconds = minutes * 60000;
    return milliseconds;
  }
}
