import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../../service/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common'
import { StrutturaService } from './../../service/struttura/struttura.service';
import { ModalService } from './../../service/modale/modal.service';
import { AutocertificazioneService } from './../../service/autocertificazione/autocertificazione.service';
import { AamService } from './../../service/aam/aam.service';
import { ListaUtentiRequest } from '../../model/request/listaUtentiRequest';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-seleziona-struttura',
  templateUrl: './seleziona-struttura.component.html',
  styleUrls: ['./seleziona-struttura.component.css']
})
export class SelezionaStrutturaComponent implements OnInit {

  selectStrutturaForm: any;
  listaUtentiRequest: ListaUtentiRequest;
  struttureList: any;
  aamResponse: any;
  utente: any;

  private mapTipologiaOperatore = new Map<String, any>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private modalService: ModalService,
    private strutturaService: StrutturaService,
    private autocertificazioneService: AutocertificazioneService,
    private aamService: AamService,
    private location: Location) {
    this.struttureList = [];
    this.listaUtentiRequest = new ListaUtentiRequest();
  }

  ngOnInit(): void {
    this.utente = this.auth.getUtente();
    this.inizializzaSelectStrutturaForm();
  }

  public conferma() {
    this.spinner.show();
    let codice = this.selectStrutturaForm.value.struttura;
    let tipologiaOperatore = this.mapTipologiaOperatore.get(codice).toUpperCase();
    this.autocertificazioneService.findLastAutocertificazioneByCfAndCodStruttura(this.utente.cf, codice, tipologiaOperatore).subscribe(
      autocertificazione => {
        if (autocertificazione != null && autocertificazione.messaggio == null) {
          this.router.navigate(['/ultimaAutocertificazione', codice]);
          this.autocertificazioneService.tipologiaOperatoreDownload = tipologiaOperatore.toUpperCase();
          this.spinner.hide();
        } else {
          this.router.navigate(['/autocertificazione', codice]);
          this.spinner.hide();
        }
      })
  }

  public back(): void {
    this.location.back();
  }

  private inizializzaSelectStrutturaForm() {
    this.spinner.show();
    let listaUtentiRequest = this.prepareRequestListaUtentiRequest();
    this.aamService.getListaUtenti(listaUtentiRequest).subscribe(
      data => {
        this.aamResponse = data['response'];
        if (this.aamResponse.length > 1) {
          this.aamResponse.forEach(element => {
            this.mapTipologiaOperatore.set(element.codiceStruttura, element.attributoValoreAsset.valore);
          });
          this.setDenominazioneStrutture();
        } else if (this.aamResponse.length > 0 && this.aamResponse.length == 1) {
          let codice = this.aamResponse[0].codiceStruttura;
          let tipologiaOperatore = this.aamResponse[0].attributoValoreAsset.valore.toUpperCase();
          this.autocertificazioneService.findLastAutocertificazioneByCfAndCodStruttura(this.utente.cf, codice, tipologiaOperatore).subscribe(
            autocertificazione => {
              if (autocertificazione != null && autocertificazione.messaggio == null) {
                this.autocertificazioneService.tipologiaOperatoreDownload = tipologiaOperatore.toUpperCase();
                this.router.navigate(['/ultimaAutocertificazione', codice]);
                this.spinner.hide();
              } else {
                this.router.navigate(['/autocertificazione', codice]);
                this.spinner.hide();
              }
            }
          )
        } else {
          this.spinner.hide();
          this.router.navigate(['/errorPage']);
        }
      });
  }

  private setDenominazioneStrutture() {
    let promise = []
    this.aamResponse.forEach(item => {
      promise.push(this.strutturaService.findStrutturaByCodiceTripletta(item.codiceStruttura));
    });
    forkJoin([...promise]).subscribe(
      values => {
        values.forEach(value => {
          this.struttureList.push({
            codStruttura: value['codiceStruttura'],
            denominazione: value['denominazione']
          });
        });
        let codice = this.struttureList[0].codStruttura;
        this.selectStrutturaForm = this.formBuilder.group({
          struttura: [codice, [Validators.required]]
        });
        this.spinner.hide();
      });
  }

  private prepareRequestListaUtentiRequest(): ListaUtentiRequest {
    let listaUtentiRequest: ListaUtentiRequest = new ListaUtentiRequest();
    listaUtentiRequest.asset = 'asso-asmi';
    listaUtentiRequest.codiceFiscale = this.utente.cf;
    listaUtentiRequest.validita = 'true';
    return listaUtentiRequest;
  }
}
