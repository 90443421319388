import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutocertificazioneService {

  tipologiaOperatoreDownload: string;



  constructor(private http: HttpClient) {

  }

  saveAutocertificazione(autocertificazioneRequest: any): Observable<any> {
    return this.http.post<any>(`${environment.asso_be}/externalServices/autocertificazioni/`, autocertificazioneRequest);
  }

  findLastAutocertificazioneByCfAndCodStruttura(cf: any, codStruttura: any, tipologiaOperatore: any): Observable<any> {
    return this.http.get<any>(`${environment.asso_be}/externalServices/autocertificazione-by-cf-and-codStruttura/?cf=${cf}&&codStruttura=${codStruttura}&&tipologiaOperatore=${tipologiaOperatore}`)
  }

  downloadAutocertificazione(cf: any, codStruttura: any, tipologiaOperatore: any): Observable<any> {
    return this.http.get<any>(`${environment.asso_be}/externalServices/autocertificazioni-download/?cf=${cf}&codStruttura=${codStruttura}&&tipologiaOperatore=${tipologiaOperatore}`);
  }

  sendMail(autocertificazioneRequest: any): Observable<any> {
    return this.http.post<any>(`${environment.asso_be}/externalServices/email/`, autocertificazioneRequest);
  }
}
