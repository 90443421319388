<div class="wrapper">
    <app-header></app-header>
    <div class="justify-content-center page-content">
        <div class="home-content">
            <h3>Asso Autocertificazioni - Seleziona una Struttura</h3>
            <div class="sezione-seleziona-struttura">
                <h4 class="sezione-nome-utente">
                    {{utente.lastName}} {{utente.name}}
                </h4>
                <form *ngIf="struttureList.length > 0" class="row col-12 col-md-12 align-items-center" name='form'
                    role="form" [formGroup]="selectStrutturaForm">
                    <label class=" col-12 col-md-5 label">Seleziona una struttura: </label>
                    <select class="form-control-sm select-struttura col-12 col-md-7" type="select" id='struttura'
                        formControlName="struttura">
                        <option *ngFor="let struttura of struttureList" value="{{struttura.codStruttura}}">
                            {{struttura.codStruttura}} {{struttura.denominazione}}</option>
                    </select>
                </form>
                <div class="row justify-content-center sezione-pulsanti-seleziona-struttura">
                    <button type="button" (click)="back()" class="btn button-strutture col-12 col-md-5">Annulla</button>
                    <button *ngIf="struttureList.length > 0" type="button" (click)="conferma()"
                        class="btn button-strutture col-12 col-md-5">Conferma</button>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>