import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from './../../service/token/token.service'
import { UtilityService } from './../../utility/utility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private utility: UtilityService
  ) { }

  ngOnInit(): void {
  }

  accedi(): void {
    if (sessionStorage.getItem('access_token') === null) {
      this.tokenService.getAuthCode();
    } else {
      this.router.navigate(["/selezionaStruttura"])
    }
    //this.fakeLoginAbilitato();
    //this.fakeLoginNonAbilitato();
  }

  fakeLoginAbilitato() {
    sessionStorage.setItem("utente", JSON.stringify({ "lastName": "BAIO", "name": "ALESSANDRO", "cf": "BAILSN73R22F704R", "rtRoles": ["Operatore Socio Sanitario", "Operatore Strutture Sociali", "Operatore SDS", "ResponsabileRSA", "Operatore Enti Locali", "Operatore Enti Accreditati SCR", "Dirigente Scolastico", "Cittadino"] }))
    this.router.navigate(["/selezionaStruttura"])
  }

  fakeLoginNonAbilitato() {
    sessionStorage.setItem("access_token", "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJnZ3VFdlBBTS1rN0Jsa0xJMnN0VFN1Q0xVcXJsbDdwUE9JaWhsZUNFRWJZIn0.eyJqdGkiOiI0YTgzYzM5ZS1mNTg1LTQ3MTYtYmY0Mi0wNWNlNDM2N2MzZjYiLCJleHAiOjE2MTU1NTc0ODMsIm5iZiI6MCwiaWF0IjoxNjE1NTU3MTgzLCJpc3MiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJzdWIiOiJmZjcwYWYxYy05ZjZkLTRmOGUtODNlZS00ZmVkYTQzNTRjY2UiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb3ZpZDE5LXNjdW9sZS1zaWN1cmUtYzEiLCJhdXRoX3RpbWUiOjE2MTU1NTcxODEsInNlc3Npb25fc3RhdGUiOiJiNmIwMzQ5NS05N2ZmLTQ3YmEtYjAzZS0yYTliNTRlNzJkYWMiLCJhY3IiOiIxIiwic2NvcGUiOiJwcm9maWxlIHJ0cm9sZXMgZGVmYXVsdCIsImZpc2NhbF9udW1iZXIiOiJUSU5JVC1GTFBDUFQ2OUE2NVozMzZQIiwiYXV0aF90eXBlIjoiRmVkZXJhdGlvbiBodHRwczovL2FycGFzdXBwb3J0LnRpeC5pdCIsImF1dGhfbGV2ZWwiOiIzIiwibmFtZSI6IkNMRU9QQVRSQSBURUEiLCJzcGlkX2NvZGUiOiIwN2U1ZTdhZC04NTVmLTRmNzMtYjdmMi1hMTIxZDA4YWQzMDQiLCJhdXRoVFMiOiIxNjE1NTU3MTgwNDQyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiRkxQQ1BUNjlBNjVaMzM2UCIsImZhbWlseV9uYW1lIjoiRklMT1BBVE9SRSIsImF1dGhJRCI6IjAxX3Rlc3Q3XzE2MTU1NTcxODAzNDZfNTY5MTUiLCJydF9yb2xlcyI6WyJPcGVyYXRvcmUgRW50aSBBY2NyZWRpdGF0aSBTQ1IiLCJDaXR0YWRpbm8iXX0.VYMGxbDsTM9-VPrZct9fRo9f8TpSPYipmXdOkcqmh6LCDC8kg8JUIOYMrgFZ1aUJf5vCv8sKQhJ1JwJYBIv8V3OK2a_9S_Ve78-xhsAHPCUazNIFjWGcnS6OG16LbDJ2RfvuWkF2XfGBSMTI64JfsanvrXaR1PTtDDK9a8wCMJn7kiyKzuuRH8a0o7JYlCHwiFgmR4GLDUj5cCixUOsGoM3hMgNlx4A3q-zbC-00TbUjHD2uN0WSYAYj1BGKzRhC1dYXTMEbky-ThOMwYwEjKIglm_1T4XIjtk2IXMFdgXNkVLXNOXPTBpHA0TP1VHgCA2gQViV8dvotd78NI9PuSg")
    this.utility.setUtente(this.tokenService.decodeToken("eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJnZ3VFdlBBTS1rN0Jsa0xJMnN0VFN1Q0xVcXJsbDdwUE9JaWhsZUNFRWJZIn0.eyJqdGkiOiI0YTgzYzM5ZS1mNTg1LTQ3MTYtYmY0Mi0wNWNlNDM2N2MzZjYiLCJleHAiOjE2MTU1NTc0ODMsIm5iZiI6MCwiaWF0IjoxNjE1NTU3MTgzLCJpc3MiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJzdWIiOiJmZjcwYWYxYy05ZjZkLTRmOGUtODNlZS00ZmVkYTQzNTRjY2UiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb3ZpZDE5LXNjdW9sZS1zaWN1cmUtYzEiLCJhdXRoX3RpbWUiOjE2MTU1NTcxODEsInNlc3Npb25fc3RhdGUiOiJiNmIwMzQ5NS05N2ZmLTQ3YmEtYjAzZS0yYTliNTRlNzJkYWMiLCJhY3IiOiIxIiwic2NvcGUiOiJwcm9maWxlIHJ0cm9sZXMgZGVmYXVsdCIsImZpc2NhbF9udW1iZXIiOiJUSU5JVC1GTFBDUFQ2OUE2NVozMzZQIiwiYXV0aF90eXBlIjoiRmVkZXJhdGlvbiBodHRwczovL2FycGFzdXBwb3J0LnRpeC5pdCIsImF1dGhfbGV2ZWwiOiIzIiwibmFtZSI6IkNMRU9QQVRSQSBURUEiLCJzcGlkX2NvZGUiOiIwN2U1ZTdhZC04NTVmLTRmNzMtYjdmMi1hMTIxZDA4YWQzMDQiLCJhdXRoVFMiOiIxNjE1NTU3MTgwNDQyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiRkxQQ1BUNjlBNjVaMzM2UCIsImZhbWlseV9uYW1lIjoiRklMT1BBVE9SRSIsImF1dGhJRCI6IjAxX3Rlc3Q3XzE2MTU1NTcxODAzNDZfNTY5MTUiLCJydF9yb2xlcyI6WyJPcGVyYXRvcmUgRW50aSBBY2NyZWRpdGF0aSBTQ1IiLCJDaXR0YWRpbm8iXX0.VYMGxbDsTM9-VPrZct9fRo9f8TpSPYipmXdOkcqmh6LCDC8kg8JUIOYMrgFZ1aUJf5vCv8sKQhJ1JwJYBIv8V3OK2a_9S_Ve78-xhsAHPCUazNIFjWGcnS6OG16LbDJ2RfvuWkF2XfGBSMTI64JfsanvrXaR1PTtDDK9a8wCMJn7kiyKzuuRH8a0o7JYlCHwiFgmR4GLDUj5cCixUOsGoM3hMgNlx4A3q-zbC-00TbUjHD2uN0WSYAYj1BGKzRhC1dYXTMEbky-ThOMwYwEjKIglm_1T4XIjtk2IXMFdgXNkVLXNOXPTBpHA0TP1VHgCA2gQViV8dvotd78NI9PuSg"))
    this.router.navigate(["/errorPage"])
  }

}
