<div class="container-header sezione-border">
    <div class="header">
        <div class="row">
            <div class="col-12 col-md-9 logoRegioneToscana d-flex align-items-center" (click)="goHome()">
                <img alt="Regione-Toscana" src="assets/img/logoToscana.png" style="height: 61px;">
                <h1 class="mainTitle">ASSO - ASMI | Autocertificazioni</h1>
            </div>
            <div class="col-12 col-md-3 d-flex centroRegionaleSection">
                <img alt="Istituto-Innocenti" style="height: 61px; margin-left: 3px;"
                    src="assets\img\Logo_Istituto_Innocenti.png">
                <img alt="Centro regionale infanzia e adolescenza"
                    src="assets/img/centro_regionale_infanzia_adolescenza.png" style="height: 80px;">
            </div>
        </div>
    </div>
</div>