import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  setUtente(token) {
    let tokenJSON = JSON.parse(token);
    let utente = {
      lastName: tokenJSON.family_name,
      name: tokenJSON.name,
      cf: tokenJSON.preferred_username,
      rtRoles: tokenJSON.rt_roles
    }
    this.auth.setUtente(utente);
  }

  storeToken(jsonRes: any) {
    sessionStorage.setItem('access_token', jsonRes.access_token);
    sessionStorage.setItem("refresh_token", jsonRes.refresh_token);
    sessionStorage.setItem("token_type", jsonRes.token_type);
  }

  deleteToken() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("token_type");
  }

}
