export const environment = {
  production: false,
  //asso_be: "./asso_be",
  //aam_be: "./aam_be",
  asso_be: "https://apistage.regione.toscana.it/C06/AssoAutocertificazioni/v1/asso",
  aam_be: "https://apistage.regione.toscana.it/C01/AttributeAuthorityManager/v1/sis_authority_manager",
  issuer: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa',
  authUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/auth',
  tokenUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/token',
  logoutUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/logout?redirect_uri=',
  redirect_uri: 'https://assoautocertificazioni-test.sanita.toscana.it',
  client_id: 'r_toscan__130__assoautocert',
  tokenSessionTime: 20,
  refresh_tokenTime: 10,
  access_tokenTime: 5
};
//https://apistage.regione.toscana.it/C06/AssoAutocertificazioni/v1/asso/autocertificazioni/externalServices/autocertificazioni/?codiceTripletta=10149_10657_10478