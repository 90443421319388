import { Component } from '@angular/core';
import { Modal } from './model/modal';
import { TokenService } from './service/token/token.service'
import { ModalService } from './service/modale/modal.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent {
  title = 'NEW - APP';

  modal_global: Modal;

  constructor(
    private tokenService: TokenService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.modal_global = new Modal();
    this.modalService.setModalGlobal(this.modal_global);

    this.tokenService.initAuthProcess();
  }


}

