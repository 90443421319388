<div class="wrapper">
    <app-header></app-header>
    <div class="justify-content-center page-content">
        <div class="home-content">
            <h3 class="text_1"> Autocertificazione per Operatori di Struttura Sociale circa l’assenza di procedimenti o
                condanne penali (art. 46 D.P.R.28 dicembre 2000 n. 445 ) </h3>
            <div class="alert alert-info alert-box text_2">
                <p>
                    La Regione Toscana, in collaborazione con Istituto degli Innocenti attraverso il Centro di
                    Documentazione regionale per l’infanzia e l’adolescenza (di cui alla L.R. 31/2000) realizza
                    il monitoraggio sui minori in famiglia fuori famiglia attraverso la raccolta, l’elaborazione e
                    l’analisi
                    dei dati forniti dalle strutture residenziali e semiresidenziali per i minori, rispondendo
                    contestualmente
                    alla rilevazione Istat Presidi, e corrisponde alle richieste del Tribunale dei Minorenni e della
                    Procura presso
                    il Tribunale dei Minorenni di Firenze (di cui agli accordi definiti con la DGR n.21 del 18-01-2021)
                    con specifiche funzionalità utilizzando i propri sistemi informativi attraverso l’applicativo
                    ASSO-ASMI.</p>
                <p>In particolare l’estrazione delle relazioni semestrali sugli accolti e delle autocertificazioni
                    dei carichi pendenti per la Procura minorile sono trattate nel rispetto della normativa in materia
                    di tutela del segreto statistico, nel rispetto delle norme che supportano i trattamenti derivanti
                    dalla
                    trasmissione e dalla condivisione di dati personali derivanti, in particolare, dalla L. 184/1983 e
                    dalla L.R.
                    41/2005, e secondo quanto riportato nella DGR 21/2021 all’art. 6 “Trattamento dei dati personali”,
                    in applicazione delle norme vigenti ed in fase di emanazione in materia di trattamento dei dati
                    personali,
                    sia primarie che secondarie, rilevanti per la corretta gestione del Trattamento, ivi compreso il
                    Regolamento UE
                    2016/679 del Parlamento europeo e del Consiglio del 27 aprile 2016 econformemente alle disposizioni,
                    alle linee guida e alle regole tecniche previste per l’accesso, la gestione e la sicurezza dei dati
                    dalla normativa
                    in materia di amministrazione digitale (in specifico, D.lgs. 82/2005 e relative linee guida e regole
                    tecniche)
                    e dalle altre norme di riferimento.
                </p>
            </div>
        </div>
        <div class="access-button">
            <button type="button" class="btn btn-primary" (click)="accedi()">Accedi</button>
        </div>
    </div>

    <app-footer></app-footer>

</div>